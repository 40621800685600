/**
 * Capitalize the first letter of a string
 *
 * @param {string} str - The string to capitalize
 * @returns {string} The capitalized string
 */
export default function capitalizeFirstLetter(str: string): string {
  /** Check if the string is empty */
  if (!str) {
    return str;
  }

  /** Capitalize the first letter and concatenate it with the rest of the string */
  return str[0].toUpperCase() + str.slice(1);
}
